<template>
  <div class="page">
    <div class="section-search">
      <div class="search-flex">
        <div class="flex-left">
          <img src="./img/left_arrow_icon.png" alt="" />
        </div>
        <div class="flex-right">
          <div class="right-input">
            <div class="input-wrap">
              <div class="input-current">
                <div class="circle"></div>
                <v-input
                  v-model="form.current"
                  :maxlength="50"
                  placeholder="请输入当前位置"
                />
              </div>
              <div class="line"></div>
              <div class="input-arrive">
                <div class="circle"></div>
                <v-input
                  v-model="form.arrive"
                  :maxlength="50"
                  placeholder="请输入当前位置"
                />
              </div>
            </div>
            <div class="input-icon">
              <img src="./img/go_icon.png" alt="" />
            </div>
          </div>
          <div class="right-tabs" v-if="!isOptions">
            <div
              :class="[
                'right-tabs--tab',
                item.value === curTab.value ? 'selectTab' : '',
              ]"
              v-for="(item, index) in tabs"
              :key="index"
              @click="chooseTab(item)"
            >
              <span>{{ item.text }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="search-options" v-if="isOptions">
        <div
          class="search-options--option"
          v-for="(option, oIndex) in options"
          :key="oIndex"
          @click="chooseOption(option)"
        >
          <div class="image"><img src="./img/search_icon.png" alt="" /></div>
          <span>A区会议厅- A区大门</span>
        </div>
        <div class=""></div>
      </div>
    </div>
    <van-Sticky v-if="!isOptions">
      <div class="section-navs">
        <div
          :class="['nav-flex', curNav.value === nav.value ? nav.className : '']"
          v-for="(nav, nIndex) in navs"
          :key="nIndex"
          @click="chooseNav(nav)"
        >
          <img :src="require(`${nav.url}`)" alt="" />
          <div class="nav-flex--text">
            <span>{{ nav.text }}</span>
          </div>
        </div>
      </div>
    </van-Sticky>
    <div class="section-main" v-if="!isOptions">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
      >
        <div
          class="list-content"
          v-for="(item, index) in listData"
          :key="index"
        >
          <div class="image">
            <img :src="item.picture" alt="" />
          </div>
          <div class="wrapper">
            <div class="wrapper-title">
              <span>{{ item.name }}</span>
            </div>
            <div class="wrapper-house">
              <span>{{ item.house }}</span>
            </div>
            <div class="wrapper-button" @click="addUnder(item)">
              <span>前往</span>
            </div>
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOptions: false, // 是否选项状态
      curTab: {
        text: "地下路径",
        value: 1,
      },
      tabs: [
        {
          text: "地下路径",
          value: 1,
        },
        {
          text: "地面路径",
          value: 2,
        },
      ],
      options: 20,
      form: {
        current: "",
        arrow: "",
      },
    };
  },
  computed: {},
  methods: {
    // 选择地上地下
    chooseTab(item) {
      this.curTab = item;
    },
    // 选择分类
    chooseNav() {},
    // 选择位置选项
    chooseOption(option) {},
  },
};
</script>
<style lang="less" scoped>
.page {
  box-sizing: border-box;
  min-height: 100%;
  width: 100%;
  background: url("./img/background.png") no-repeat left top;
  background-size: 100% 484px;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .section-search {
    box-sizing: border-box;
    width: 100%;
    //  height: 484px;
    padding: 126px 30px 64px;
    .search-flex {
      display: flex;
      width: 100%;
      // height: 100%;
      box-sizing: border-box;
      padding: 30px;
      background: #ffffff;
      border-radius: 16px;
      box-shadow: 0px 4px 22px 0px rgba(229, 229, 229, 0.5);
      .flex-left {
        margin-right: 48px;
        img {
          height: 26px;
          width: 14px;
        }
      }
      .flex-right {
        flex: 1;
        .right-input {
          display: flex;
          align-items: center;
          background: #f9f9f9;
          width: 100%;
          height: 160px;
          border-radius: 16px;
          //  font-size: 28px;
          .input-wrap {
            display: flex;
            flex-direction: column;
            height: 100%;
            .input-current,
            .input-arrive {
              flex: 1;
              display: flex;
              align-items: center;
              .circle {
                margin: 0 12px 0 28px;
                display: flex;
                justify-content: center;
                align-items: center;
                &::before {
                  content: "";
                  width: 12px;
                  height: 12px;
                  background: #fff;
                  border-radius: 50%;
                  border: 6px solid #68cfa8;
                }
              }
              ::v-deep .van-cell {
                width: 420px;
                background-color: transparent;
                line-height: 40px;
                padding: 0;
                font-size: 28px;
                color: rgba(0, 0, 0, 0.85);
              }
            }
            .line {
              margin: 0 18px 0 50px;
              width: 420px;
              height: 2px;
              background: #f1f1f1;
            }
            .input-arrive {
              .circle {
                &::before {
                  border: 6px solid #fa6400;
                }
              }
            }
          }
          .input-icon {
            img {
              width: 64px;
              height: 64px;
            }
          }
        }
        .right-tabs {
          display: flex;
          margin-top: 22px;
          font-size: 26px;
          font-weight: 500;
          .right-tabs--tab {
            border-radius: 10px;
            padding: 8px 14px;
            margin-right: 36px;
            color: rgba(0, 0, 0, 0.5);
            &.selectTab {
              background: linear-gradient(180deg, #549afe, #247dff);
              color: #ffffff;
            }
          }
        }
      }
    }
    .search-options {
      margin-top: 20px;
      background: #ffffff;
      box-shadow: 0px 4px 22px 0px rgba(229, 229, 229, 0.5);
      padding: 0px 22px 22px;
      font-size: 28px;
      color: rgba(0, 0, 0, 0.85);
      .search-options--option {
        display: flex;
        padding: 30px 12px;
        border-bottom: 2px solid #eeeeee;
        line-height: 40px;
        .image {
          padding-top: 4px;
          img {
            width: 28px;
            height: 28x;
            margin: 0 18px 0 0;
          }
        }
        span {
          word-wrap: break-word;
          word-break: break-all;
        }
      }
    }
  }
}
</style>
