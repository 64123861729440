<template>
  <div class="page">
    <iframe
      id="planningIframe"
      class="bgIframe"
      :src="srcUrl"
      frameborder="0"
      width="100%"
      height="100%"
    ></iframe>
    <div class="footer">
      <div class="footer-flex">
        <div class="warpper-flex">
          <div class="warpper-tip" @click="back">
            <div class="warpper-tip--flex">
              <img src="./img/left_arrow_icon.png" alt="" />
              <span>路线规划</span>
            </div>
          </div>
          <div class="warpper-content">
            <div
              :class="[
                'section-list',
                current.id === item.id && current.desc === item.desc
                  ? 'current'
                  : '',
              ]"
              v-for="(item, index) in listData"
              :key="index"
              @click="choosePlan(item)"
            >
              <div class="list-tip"><span>规划中</span></div>
              <div class="list-address">
                <span>{{ item.desc }}</span>
              </div>
              <!-- <div class="list-distance">{{ "--" }} 米</div> -->
              <div class="list-distance">第 {{ index + 1 }} 步</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { stepURL } from "./api";

export default {
  data() {
    return {
      loaded: false,
      srcUrl: "https://project.zhuneng.cn/demo/#/iframe",
      focus: undefined,
      form: {
        start: undefined, // 开始
        curStart: "",
        end: undefined, // 前往
        arrowEnd: "",
      },
      curTab: {},
      current: {},
      listData: [],
    };
  },
  beforeRouteLeave(to, from, next) {
    sessionStorage.setItem(
      "routePlanQuery",
      JSON.stringify({
        form: this.form,
        curTab: this.curTab,
      })
    );
    next();
  },
  created() {
    window.addEventListener("message", this.changeLoaded);
  },
  mounted() {
    let { form, curTab, focus } = this.$route.query;
    this.form = JSON.parse(form);
    this.curTab = JSON.parse(curTab);
    this.focus = focus;
    this.onLoad();
  },
  beforeDestroy() {
    window.removeEventListener("message", this.changeLoaded);
  },
  methods: {
    back() {
      this.$router.go(-1);
      // this.$router.push({
      //   name: "routePlan",
      // });
    },
    changeLoaded(ev) {
      // 监听iframe资源是否加载完成，可以发送postmessage
      console.log(ev);
      if (ev.data && ev.data.type === "loaded") {
        this.loaded = true;
        //进行操作
      }
    },
    showIframe() {
      let iframe = document.getElementById("planningIframe").contentWindow;
      iframe.postMessage(
        // Object,start和end为POI的id，id为导航BIM的资源id
        { type: "maze", data: this.current },
        "*"
      );
    },
    choosePlan(item) {
      if (item.guide) {
        this.current = item;
        this.showIframe();
      }
    },

    // 加载列表数据
    onLoad() {
      this.$axios
        .get(stepURL, {
          params: {
            start: this.form.start, // 开始
            end: this.form.end, // 前往
            priorityFloor: this.curTab.value, // 地下,  地上
          },
        })
        .then((res) => {
          if (res.code == 0 && res.data) {
            this.listData = res.data;
            this.current = this.listData.filter((v) => v.guide)[0];
            let iframe =
              document.getElementById("planningIframe").contentWindow;
            if (iframe && this.current.guide) {
              this.$toast.loading({
                duration: 0,
                message: "加载中...",
                forbidClick: true,
                loadingType: "spinner",
              });
              const _this = this;
              const timer = setInterval(() => {
                if (_this.loaded) {
                  this.$toast.clear();
                  this.showIframe();
                  clearTimeout(timer);
                }
              }, 300);
            }
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.page {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #ededed;
  -webkit-overflow-scrolling: touch; /*看这里*/
  .bgIframe {
    border: 0;
    vertical-align: top;
  }
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 160px;
    background: #ffffff;
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
    .footer-flex {
      position: relative;
      height: 100%;
      margin: 0 30px;
      background: #ffffff;
      .warpper-flex {
        position: absolute;
        bottom: 20px;
        left: 0;
        width: 100%;
        .warpper-tip {
          display: inline-block;
          box-shadow: 0px 4px 22px 0px #e5e5e5;
          border-radius: 20px;
          font-size: 32px;
          line-height: 45px;
          padding: 20px 36px;
          margin-bottom: 20px;
          background: #ffffff;
          .warpper-tip--flex {
            display: flex;
            align-items: center;
            img {
              height: 26px;
              width: 14px;
              margin-right: 20px;
              vertical-align: middle;
            }
            span {
              vertical-align: middle;
            }
          }
        }
        .warpper-content {
          width: 100%;
          min-height: 230px;
          background: #f9f9f9;
          display: flex;
          border-radius: 20px;
          overflow-x: auto;
          overflow-y: hidden;
          box-shadow: 0px 4px 22px 0px rgba(229, 229, 229, 0.5);
          .section-list {
            box-sizing: border-box;
            width: 32%;
            min-width: 32%;
            color: rgba(229, 229, 229, 0.5);
            padding: 22px 26px;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: rgba(0, 0, 0, 0.85);
            &:not(:first-child) {
              margin-left: 2%;
            }
            .list-tip {
              opacity: 0;
              height: 34px;
              font-size: 24px;
            }
            .list-address {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              font-weight: 600;
              font-size: 26px;
              // margin: 20px 0;
            }
            .list-distance {
              font-size: 24px;
            }
            &.current {
              color: #237dff;
              background: #fff;
              box-shadow: 0px 4px 22px 0px rgba(229, 229, 229, 0.5);
              .list-tip {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}
</style>
