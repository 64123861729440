<template>
  <div class="page">
    <div class="section-search">
      <div class="search-flex">
        <div class="flex-left">
          <img @click="back" src="./img/clear.png" alt="" />
        </div>
        <div class="flex-right">
          <div class="right-input">
            <div class="input-wrap">
              <div class="input-current">
                <div class="circle"></div>
                <!-- <v-input
                  v-model="form.current"
                  :maxlength="20"
                  placeholder="请输入当前位置"
                /> -->
                <div class="text" @click="toRouter('1')">
                  <span>{{ form.curStart || "请输入当前位置" }}</span>
                </div>
              </div>
              <div class="line"></div>
              <div class="input-arrive" @click="toRouter('2')">
                <div class="circle"></div>
                <!-- <v-input
                  v-model="form.arrive"
                  :maxlength="20"
                  placeholder="请输入当前位置"
                /> -->
                <div class="text">
                  <span>{{ form.arrowEnd || "请输入到达位置" }}</span>
                </div>
              </div>
            </div>
            <div class="input-icon" v-show="isShowIcon" @click="toPlan">
              <img src="./img/go_icon.png" alt="" />
            </div>
          </div>
          <div class="right-tabs">
            <div
              :class="[
                'right-tabs--tab',
                item.value === curTab.value ? 'selectTab' : '',
              ]"
              v-for="(item, index) in tabs"
              :key="index"
              @click="chooseTab(item)"
            >
              <span>{{ item.text }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-Sticky>
      <div class="section-navs">
        <div
          :class="['nav-flex', curNav.value === nav.value ? nav.className : '']"
          v-for="(nav, nIndex) in navs"
          :key="nIndex"
          @click="chooseNav(nav)"
        >
          <img :src="require(`${nav.url}`)" alt="" />
          <div class="nav-flex--text">
            <span>{{ nav.text }}</span>
          </div>
        </div>
      </div>
    </van-Sticky>
    <div class="section-main">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
      >
        <div
          class="list-content"
          v-for="(item, index) in listData"
          :key="index"
        >
          <div class="image">
            <img :src="item.picture" alt="" />
          </div>
          <div class="wrapper">
            <div class="wrapper-title">
              <span>{{ item.name }}</span>
            </div>
            <div class="wrapper-house">
              <span>{{ item.house }}</span>
            </div>
            <div
              class="wrapper-button"
              @click="addUnder(item)"
              v-show="item.id !== form.end"
            >
              <span>{{ "前往" }}</span>
            </div>
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { Sticky } from "vant";
import { dataListURL } from "./api";

export default {
  components: {
    vanSticky: Sticky,
  },
  data() {
    return {
      isInput: true, // 是否输入框输入状态
      isShowIcon: false,
      curTab: {
        text: "地面路径",
        value: 0,
      },
      tabs: [
        {
          text: "地下路径",
          value: -1,
        },
        {
          text: "地面路径",
          value: 0,
        },
      ],
      curNav: {},
      navs: [
        {
          text: "卫生间",
          url: "./img/toilet.png",
          className: "toilet",
          value: 1,
        },
        {
          text: "手扶梯",
          url: "./img/escalator.png",
          className: "escalator",
          value: 2,
        },
        {
          text: "VIP",
          url: "./img/vip.png",
          className: "vip",
          value: 3,
        },
        {
          text: "客梯",
          url: "./img/passengerLadder.png",
          className: "passengerLadder",
          value: 4,
        },
        {
          text: "会议厅",
          url: "./img/conferenceHall.png",
          className: "conferenceHall",
          value: 5,
        },
      ],
      form: {
        start: undefined, // 开始
        curStart: "",
        end: undefined, // 前往
        arrowEnd: "",
      },
      listData: [
        // {
        //   picture:
        //     "https://fenghua.zhuneng.cn/imageproxy/226x150/https://fenghua.zhuneng.cn/data/minio/leo/upload/20221028/5da847004d3f2bfbc61509c04812c1b5.png",
        //   name: "会客室",
        //   house: "1F/202室",
        // },
        // {
        //   picture:
        //     "https://fenghua.zhuneng.cn/imageproxy/226x150/https://fenghua.zhuneng.cn/data/minio/leo/upload/20221028/5da847004d3f2bfbc61509c04812c1b5.png",
        //   name: "会客室",
        //   house: "1F/202室",
        // },
        // {
        //   picture:
        //     "https://fenghua.zhuneng.cn/imageproxy/226x150/https://fenghua.zhuneng.cn/data/minio/leo/upload/20221028/5da847004d3f2bfbc61509c04812c1b5.png",
        //   name: "会客室",
        //   house: "1F/202室",
        // },
        // {
        //   picture:
        //     "https://fenghua.zhuneng.cn/imageproxy/226x150/https://fenghua.zhuneng.cn/data/minio/leo/upload/20221028/5da847004d3f2bfbc61509c04812c1b5.png",
        //   name: "会客室",
        //   house: "1F/202室",
        // },
        // {
        //   picture:
        //     "https://fenghua.zhuneng.cn/imageproxy/226x150/https://fenghua.zhuneng.cn/data/minio/leo/upload/20221028/5da847004d3f2bfbc61509c04812c1b5.png",
        //   name: "会客室",
        //   house: "1F/202室",
        // },
      ],
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      // listData: [], // 列表数据集合
      curPage: 1,
      pageSize: 20,
    };
  },
  watch: {
    form: {
      handler(val) {
        const { curStart, arrowEnd } = val;
        this.isShowIcon = curStart && arrowEnd;
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    const routePlanQuery = sessionStorage.getItem("routePlanQuery");
    console.log(routePlanQuery);
    if (routePlanQuery) {
      let { form, curTab } = JSON.parse(routePlanQuery);
      this.form = form;
      this.curTab = curTab;
    }
    sessionStorage.clear();
  },
  // beforeRouteLeave(to, from, next) {
  //   next();
  // },
  methods: {
    back() {
      // this.$router.go(-1);
      this.form = {
        start: undefined, // 开始
        curStart: "",
        end: undefined, // 前往
        arrowEnd: "",
      };
    },
    // 点击输入地点、到达地点
    toRouter(n) {
      this.$router.replace({
        name: "routePlanSearch",
        query: {
          focus: n, // 1 当前 2 到达
          form: JSON.stringify(this.form),
          curTab: JSON.stringify(this.curTab),
        },
      });
    },
    // 前往路线规划页面
    toPlan() {
      let result = this.vaild();
      if (result) {
        this.$router.push({
          name: "routePlanPlanning",
          query: {
            form: JSON.stringify(this.form),
            curTab: JSON.stringify(this.curTab),
            focus: this.focus,
          },
        });
      }
    },
    // 选择地上地下
    chooseTab(tab) {
      this.curTab = tab;
      this.onRefresh();
    },
    // 选择分类
    chooseNav(nav) {
      if (this.curNav.value === nav.value) {
        this.curNav = {};
        return;
      }
      this.curNav = nav;
      this.onRefresh();
    },
    // 前往按钮
    addUnder(item) {
      this.form.end = item.id;
      this.form.arrowEnd = item.name;
    },
    // 重置
    onRefresh() {
      this.curPage = 1;
      this.listData = [];
      this.onLoad();
    },
    vaild() {
      if (!this.form.start) {
        this.$toast({ message: "请输入正确的当前位置", duration: 1500 });
        return;
      } else if (!this.form.end) {
        this.$toast({ message: "请输入正确的到达位置", duration: 1500 });
        return;
      } else if (this.form.start === this.form.end) {
        this.$toast({ message: "起点等于终点，无需导航", duration: 1500 });
        return;
      }
      return true;
    },
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(dataListURL, {
          params: {
            start: this.form.start, // 开始
            end: this.form.end, // 前往
            priorityFloor: this.curTab.value, // 地下,  地上
          },
        })
        .then((res) => {
          if (res.code != 200) {
            this.finished = true;
          } else {
            if (this.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              this.listData = [];
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.curPage === 1) {
                res.data.records.forEach((item) => {
                  if (item.picture) {
                    item.picture = this.$handleImg(232, 232, item.picture);
                  }
                });
                this.listData = res.data.records;
              } else {
                const list = this.listData.map((v) => v.id);
                res.data.records.forEach((item) => {
                  if (item.picture) {
                    item.picture = this.$handleImg(232, 232, item.picture);
                  }
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.id) || this.listData.push(item);
                });
              }
              this.curPage++;
            }
          }
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style lang="less" scoped>
::-webkit-scrollbar {
  display: none;
}
.page {
  box-sizing: border-box;
  min-height: 100%;
  width: 100%;
  background: url("./img/background.png") no-repeat left top;
  background-size: 100% 484px;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .section-search {
    box-sizing: border-box;
    width: 100%;
    //  height: 484px;
    padding: 126px 30px 64px;
    .search-flex {
      display: flex;
      width: 100%;
      // height: 100%;
      box-sizing: border-box;
      padding: 30px;
      background: #ffffff;
      border-radius: 16px;
      box-shadow: 0px 4px 22px 0px rgba(229, 229, 229, 0.5);
      .flex-left {
        margin-right: 48px;
        img {
          // height: 26px;
          // width: 14px;
          height: 24px;
          width: 24px;
        }
      }
      .flex-right {
        flex: 1;
        .right-input {
          display: flex;
          align-items: center;
          background: #f9f9f9;
          width: 100%;
          height: 160px;
          border-radius: 16px;
          //  font-size: 28px;
          .input-wrap {
            display: flex;
            flex-direction: column;
            height: 100%;
            .input-current,
            .input-arrive {
              flex: 1;
              display: flex;
              align-items: center;
              margin-right: 64px;
              .circle {
                margin: 0 12px 0 28px;
                display: flex;
                justify-content: center;
                align-items: center;
                &::before {
                  content: "";
                  width: 12px;
                  height: 12px;
                  background: #fff;
                  border-radius: 50%;
                  border: 6px solid #68cfa8;
                }
              }
              ::v-deep .van-cell {
                width: 356px;
                background-color: transparent;
                line-height: 40px;
                padding: 0;
                font-size: 28px;
                color: rgba(0, 0, 0, 0.85);
              }
              .text {
                width: 356px;
                // text-overflow: ellipsis;
                white-space: nowrap;
                overflow-y: hidden;
                overflow-x: auto;
                line-height: 40px;
                padding: 0;
                font-size: 28px;
                color: rgba(0, 0, 0, 0.85);
              }
            }
            .line {
              margin: 0 18px 0 50px;
              width: 420px;
              height: 2px;
              background: #f1f1f1;
            }
            .input-arrive {
              .circle {
                &::before {
                  border: 6px solid #fa6400;
                }
              }
            }
          }
          .input-icon {
            img {
              width: 64px;
              height: 64px;
              vertical-align: middle;
            }
          }
        }
        .right-tabs {
          display: flex;
          margin-top: 22px;
          font-size: 26px;
          font-weight: 500;
          .right-tabs--tab {
            border-radius: 10px;
            padding: 8px 14px;
            margin-right: 36px;
            color: rgba(0, 0, 0, 0.5);
            &.selectTab {
              background: linear-gradient(180deg, #549afe, #247dff);
              color: #ffffff;
            }
          }
        }
      }
    }
    //  .search-options {
    //    margin-top: 20px;
    //    background: #ffffff;
    //    box-shadow: 0px 4px 22px 0px rgba(229, 229, 229, 0.5);
    //    padding: 0px 22px 22px;
    //    font-size: 28px;
    //    color: rgba(0, 0, 0, 0.85);
    //    .search-options--option {
    //      display: flex;
    //      padding: 30px 12px;
    //      border-bottom: 2px solid #eeeeee;
    //      line-height: 40px;
    //      .image {
    //        padding-top: 4px;
    //        img {
    //          width: 28px;
    //          height: 28x;
    //          margin: 0 18px 0 0;
    //        }
    //      }
    //      span {
    //        word-wrap: break-word;
    //        word-break: break-all;
    //      }
    //    }
    //  }
  }
  //   ::v-deep .van-sticky {
  //   }
  .section-navs {
    padding: 40px 30px 24px;
    background: #fff;
    display: flex;
    .nav-flex {
      flex: 1;
      text-align: center;
      img {
        box-sizing: border-box;
        width: 120px;
        height: 120px;
        vertical-align: middle;
      }
      .nav-flex--text {
        line-height: 40px;
        text-align: center;
        margin-top: 18px;
        font-size: 26px;
        color: rgba(0, 0, 0, 0.5);
      }
      &.toilet,
      &.escalator,
      &.vip,
      &.passengerLadder,
      &.conferenceHall {
        img {
          border-radius: 10px;
        }
        .nav-flex--text {
          font-weight: bold;
          font-size: 28px;
          color: rgba(0, 0, 0, 0.85);
        }
      }
      &.toilet {
        img {
          border: 2px solid #237dff;
        }
      }
      &.escalator {
        img {
          border: 2px solid #05d2fc;
        }
      }
      &.vip {
        img {
          border: 2px solid #2adfc5;
        }
      }
      &.passengerLadder {
        img {
          border: 2px solid #fba90f;
        }
      }
      &.conferenceHall {
        img {
          border: 2px solid #237dff;
        }
      }
    }
  }
  .section-main {
    margin-top: 40px;
    .list-content {
      display: flex;
      margin: 30px 30px 0;
      // background: #fff;
      border-radius: 16px;
      box-shadow: 0px 4px 22px 0px rgba(229, 229, 229, 0.5);
      background: url("./img/hot_icon.png") no-repeat 536px 20px;
      background-size: 170px 232px;
      .image {
        box-sizing: border-box;
        border-radius: 16px 0 0 16px;
        margin-right: 36px;
        width: 232px;
        min-height: 232px;
        overflow: hidden;
        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .wrapper {
        flex: 1;
        //   display: flex;
        //   flex-direction: column;
        padding: 30px 36px 24px 0;
        word-wrap: break-word;
        word-break: break-all;
        .wrapper-title {
          flex: 1;
          font-size: 32px;
          color: rgba(0, 0, 0, 0.85);
        }
        .wrapper-house {
          min-height: 40px;
          margin-top: 12px;
          display: flex;
          align-items: center;
          font-size: 28px;
          color: rgba(0, 0, 0, 0.5);
        }
        .wrapper-button {
          display: inline-block;
          margin-top: 22px;
          border-radius: 10px;
          background: linear-gradient(180deg, #549afe, #247dff);
          color: #ffffff;
          font-size: 26px;
          padding: 12px 40px;
        }
      }
    }
  }
}
</style>
